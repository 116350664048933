import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {CirclesWithBar} from "react-loader-spinner";

interface LoginProps {
    loggedIn: boolean,
    setLoggedIn: any
}

export const Login = (props: LoginProps) => {
    const { loggedIn, setLoggedIn } = props;
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [showSpinner, setShowSpinner] = useState(false);

    const navigate = useNavigate();

    const login = () => {
        setShowSpinner(true)
        axios
            .post('https://exqz0xm53g.execute-api.us-east-1.amazonaws.com/Stage/login', {"email": email, "password": password})
            .then((response) => {
                if(response.data.status === 'SUCCESS'){
                    window.localStorage.setItem('vaydToken', response.data.token);
                    setLoggedIn(true);
                } else if(response.data.status === 'UNAUTHORIZED'){
                    window.localStorage.setItem('vaydToken', '');
                    window.location.href = '/login';
                }
            }).catch(err => {
                window.localStorage.setItem('vaydToken', '');
                setLoggedIn(false);
                setShowSpinner(false)
            });
    }

    const onButtonClick = () => {
        // Set initial error values to empty
        setEmailError("")
        setPasswordError("")

        // Check if the user has entered both fields correctly
        if ("" === email) {
            setEmailError("Please enter your email")
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError("Please enter a valid email")
            return
        }

        if ("" === password) {
            setPasswordError("Please enter a password")
            return
        }

        if (password.length < 7) {
            setPasswordError("The password must be 8 characters or longer")
            return
        }

        login();
    }

    useEffect(() => {
        if(window.localStorage.getItem('vaydToken')) {
            window.location.href = '/';
        }
    },[loggedIn]);
    return (<div className={"mainContainer"}>
        {!showSpinner && (<><div className={"titleContainer"}>
            <div>Login</div>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                value={email}
                placeholder="Enter your email here"
                onChange={ev => setEmail(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{emailError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                type={"password"}
                value={password}
                placeholder="Enter your password here"
                onChange={ev => setPassword(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{passwordError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                className={"inputButton"}
                type="button"
                onClick={onButtonClick}
                value={"Log in"} />
        </div></>)}
        {showSpinner && (<div style={{width: '10%', margin: 'auto', paddingTop: '250px'}}>
            Logging You In
            <CirclesWithBar
                height="80"
                width="80"
                color="green"
                ariaLabel="loading"
            />
        </div>)}
    </div>)
}