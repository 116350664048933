import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { Audio } from 'react-loader-spinner'
Chart.register(...registerables);
export function Load30Day() {
    const [data, setData] = useState({datasets: [], labels: []});
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        const token: string | null =  window.localStorage.getItem('vaydToken');
        if(!token) {
            window.location.href = '/login';
        }
        const headers = {"vaydAuth": token || false};
        axios
            .get('https://exqz0xm53g.execute-api.us-east-1.amazonaws.com/Prod/', {headers})
            .then((response) => {
                const total: any = [];
                const dates: any = [];
                const employees = response.data;
                let load = false;
                employees.forEach((employee: any) => {
                    const totalPrac: any = [];
                    // console.log(employee);
                    employee.data.forEach((payment: any) => {
                        if (!load) {
                            dates.push(`${payment.monn}, ${payment.day}`);
                        }
                        if (payment) {
                            totalPrac.push(payment.revenue);
                        }
                    });
                    load = true;
                    total.push({
                        showLine: true,
                        fill: false,
                        borderColor: `rgba(${200 * Math.random()}, ${200 * Math.random()}, ${200 * Math.random()}, 1)`,
                        label: employee.employee.firstName,
                        data: totalPrac,
                    });
                });

                // console.log(total);
                // console.log(dates);
                setData({
                        labels: dates,
                        datasets: total,
                });
                setShowSpinner(false);
            })
            .catch(err => {
                window.localStorage.setItem('vaydToken', '');
                window.location.href = '/login';
            });
    }, []);
    
    return (
        <div>
            {showSpinner && (<div style={{width: '10%', margin: 'auto', paddingTop: '250px'}}>
                Loading 30 Day
               <Audio
                height="80"
                width="80"
                color="green"
                ariaLabel="loading"
            />
            </div>)}
            <Line
                data={data}
            />
        </div>
        
);

}