import React, {useState} from 'react';
import './App.css';
import {Load30Day} from './dataService'
import {LoadRecordData} from './recordData'
import {LoadYearToYear} from './yearToYear'
import {LoadWeekToWeek} from './dataWeekToWeek'
import {RevenueByMonth} from './revenuByMonth';
import {Login} from './login';
import {Home} from './home';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";

function App() {
    const [loggedIn, setLoggedIn] = useState(false)
    const [email, setEmail] = useState("")
        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={
                            <Home loggedIn={loggedIn}/>
                        } />
                        <Route path="/login" element={<Login loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </BrowserRouter>
            </div>
        );

}

export default App;
