import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function LoadRecordData() {
    const [data, setData] = useState({months: [], days:[], years:[]});
    useEffect(() => {
        const token: string | null =  window.localStorage.getItem('vaydToken');
        if(!token) {
            window.location.href = '/login';
        }
        const headers = {"vaydAuth": token || false};
        axios
            .get('https://exqz0xm53g.execute-api.us-east-1.amazonaws.com/Prod/records', {headers})
            .then((response) => {
                setData(response.data[0].data)
            }).catch(err => {
                window.localStorage.setItem('vaydToken', '');
                window.location.href = '/login';
            });
    }, []);

    return (
        <div className="ag-theme-alpine" style={{height: 600, width: 800}}>
            <h1>Months(Cash Received)</h1>
            <AgGridReact
                rowData={data.months}
                columnDefs={[
                    { field: 'mon' },
                    { field: 'revenue' },
                    { field: 'yyyy' }
                ]}>
            </AgGridReact>
            <h1>Days(Cash Received)</h1>
            <AgGridReact
                rowData={data.days}
                columnDefs={[
                    { field: 'dayy' },
                    { field: 'mon' },
                    { field: 'revenue' },
                    { field: 'yyyy' }
                ]}>
            </AgGridReact>
            <h1>Years(Cash Received)</h1>
            <AgGridReact
                rowData={data.years}
                columnDefs={[
                    { field: 'revenue' },
                    { field: 'yyyy' }
                ]}>
            </AgGridReact>
        </div>

    );
}
