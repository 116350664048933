import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {LoadWeekToWeek} from "./dataWeekToWeek";
import {Load30Day} from "./dataService";
import {LoadYearToYear} from "./yearToYear";
import {RevenueByMonth} from "./revenuByMonth";
import {LoadRecordData} from "./recordData";

interface HomeProps {
        loggedIn: boolean
}

export const Home = (props: HomeProps) => {
        const { loggedIn } = props;
        useEffect(() => {
                if(!window.localStorage.getItem('vaydToken')) {
                        window.location.href = '/login';
                }
        },[loggedIn]);
        if(window.localStorage.getItem('vaydToken')) {
                return <div>
                        <h1>Week To Week(Services Delivered)</h1>
                        <LoadWeekToWeek/>
                        <h1>30 Days(Services Delivered)</h1>
                        <Load30Day/>
                        <h1>Year To Year</h1>
                        <LoadYearToYear/>
                        <h1>Revenue By Month(Services Delivered)</h1>
                        <RevenueByMonth/>
                        <h1>Records(FUCK YEAH!)</h1>
                        <LoadRecordData/>
                </div>
        } else {
                window.location.href = '/login';
                return <div></div>
        }
}