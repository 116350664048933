import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Line } from 'react-chartjs-2';
import {Audio} from "react-loader-spinner";

export function LoadYearToYear() {
    const [data, setData] = useState({datasets: [], labels: []});
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        const token: string | null =  window.localStorage.getItem('vaydToken');
        if(!token) {
            window.location.href = '/login';
        }
        const headers = {"vaydAuth": token || false};
        axios
            .get('https://exqz0xm53g.execute-api.us-east-1.amazonaws.com/Prod/yeartoyear', {headers})
            .then((response) => {
                const total: any = [];
                const dates: any = [];
                const years = response.data;
                let load = false;
                years.forEach((year: any) => {
                    const totalPrac: any = [];

                    year.data.forEach((month: any) => {
                        if (!load) {
                            dates.push(month.mon);
                        }
                        if (year) {
                            totalPrac.push(month.revenue);
                        }
                    });
                    load = true;
                    total.push({
                        showLine: true,
                        fill: false,
                        borderColor: `rgba(${200 * Math.random()}, ${200 * Math.random()}, ${200 * Math.random()}, 1)`,
                        label: year.year,
                        data: totalPrac,
                    });
                });

                // console.log(total);
                // console.log(dates);
                setData({
                    labels: dates,
                    datasets: total,
                });
                setShowSpinner(false);
            }).catch(err => {
                setShowSpinner(false);
                window.localStorage.setItem('vaydToken', '');
                window.location.href = '/login';
            });
    }, []);

    return (
        <div>
            {showSpinner && (<div style={{width: '10%', margin: 'auto', paddingTop: '250px'}}>
                Loading Yearly Data
                <Audio
                    height="80"
                    width="80"
                    color="green"
                    ariaLabel="loading"
                />
            </div>)}
            <Line
                data={data}
            />
        </div>)
}
